.App {
  background-color: #111;
  min-height: 100vh;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
}

.content-container {
  display: flex;
  gap: 6rem;
  max-width: 1800px;
  width: 100%;
  align-items: center;
}

.text-section {
  flex: 1;
  text-align: left;
}

.name-title {
  font-size: 5.5rem;
  margin-bottom: 3rem;
  background: linear-gradient(45deg, #fff, #f0f0f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 20px rgba(255,255,255,0.1);
  font-weight: bold;
}

.bio-list {
  font-size: 1.8rem;
  line-height: 2;
}

.bio-list p {
  margin: 1.5rem 0;
  transition: transform 0.2s;
}

.bio-list p:hover {
  transform: translateX(10px);
}

a {
  color: #fff;
  text-decoration: none;
  border-bottom: 3px solid #ff69b4;
  transition: color 0.3s;
  padding-bottom: 2px;
}

a:hover {
  color: #ff69b4;
}

/* New Contact Links Styles */
.contact-links {
  display: flex;
  gap: 2rem;
  margin: 3rem 0;
}

.social-button {
  padding: 1.2rem 3rem;
  font-size: 1.4rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.social-button.linkedin {
  background: linear-gradient(45deg, #0077b5, #00a0dc);
  color: white;
  box-shadow: 0 4px 15px rgba(0, 119, 181, 0.3);
}

.social-button.email {
  background: linear-gradient(45deg, #f5f5f5, #ffffff);
  color: #111;
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.2);
}

.social-button:hover {
  transform: translateY(-3px) scale(1.05);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.3);
}

.social-button.linkedin:hover {
  background: linear-gradient(45deg, #00a0dc, #0077b5);
}

.social-button.email:hover {
  background: linear-gradient(45deg, #ffffff, #f5f5f5);
}

/* Existing CTA Button */
.cta-button {
  background: #ff69b4;
  border: none;
  padding: 1.5rem 3.5rem;
  font-size: 1.6rem;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  margin: 3rem 0;
  transition: transform 0.3s, box-shadow 0.3s;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 15px 30px rgba(255,105,180,0.4);
  background: #ff1493;
}

.video-container {
  flex: 1;
  aspect-ratio: 16/9;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 40px rgba(0,0,0,0.6);
}

.video-container iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1400px) {
  .content-container {
    flex-direction: column;
    gap: 3rem;
  }

  .text-section {
    text-align: center;
  }

  .name-title {
    font-size: 4rem;
  }
  
  .bio-list {
    font-size: 1.4rem;
  }

  .video-container {
    width: 100%;
  }

  .cta-button {
    padding: 1.2rem 3rem;
    font-size: 1.4rem;
  }

  .contact-links {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .name-title {
    font-size: 3rem;
  }
  
  .bio-list {
    font-size: 1.2rem;
  }

  .cta-button {
    padding: 1rem 2.5rem;
    font-size: 1.2rem;
  }

  .social-button {
    padding: 1rem 2rem;
    font-size: 1.2rem;
  }
}
/* First, keep your general a tag styles */
a {
  color: #fff;
  text-decoration: none;
  border-bottom: 3px solid #ff69b4;  /* This is causing the pink underline */
  transition: color 0.3s;
  padding-bottom: 2px;
}

/* Add this new rule to remove border for social buttons */
.contact-links a {
  border-bottom: none;
  padding-bottom: 0;
}